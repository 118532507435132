@import "./_mantine.scss";
.cardHidden {
  display: none;
}

.card {
  background-color: #fff;
  border-radius: 200px;
  padding: 0.25rem;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border: 1px solid var(--mantine-color-gray-2);
  &:hover {
    cursor: pointer;
  }
}

.filterLabel {
  font-size: var(--mantine-font-size-md);
  color: #888888;
  margin-bottom: 0;
  font-weight: 600;
  text-overflow: ellipsis;
}

.search {
  background: var(--mantine-color-pink-6);
  color: white;
  &:hover {
    background-color: var(--mantine-other-pinkHover) !important;
  }
}

@import "./_mantine.scss";
.primaryBtn {
  background-color: var(--mantine-color-pink-6);
  color: var(--mantine-color-white);
  border-radius: 24px;
  &:hover {
    background-color: var(--mantine-other-pinkHover);
    color: var(--mantine-color-white);
  }
}

.secondaryBtn {
  border: 1px solid var(--mantine-color-pink-6);
  border-radius: 24px;
  background-color: transparent;
  color: var(--mantine-color-pink-6);
  &:hover {
    border: 1px solid var(--mantine-other-pinkHover);
    color: var(--mantine-other-pinkHover);
  }
}

.tertiaryBtn {
  background-color: transparent;
  color: var(--mantine-color-blue-6);
  border-radius: 24px;
  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  border: 1px solid var(--mantine-color-gray-4);
  background-color: var(--mantine-color-gray-1);
  color: var(--mantine-color-gray-4);
  svg {
    fill: var(--mantine-color-gray-4);
  }
  &:hover {
    cursor: not-allowed;
    text-decoration: none;
    border: 1px solid var(--mantine-color-gray-4);
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-4);
    svg {
      fill: var(--mantine-color-gray-4);
    }
  }
}

.disabledTertiary {
  color: var(--mantine-color-gray-4);
  svg {
    fill: var(--mantine-color-gray-4);
  }
  &:hover {
    cursor: not-allowed;
    text-decoration: none;
    color: var(--mantine-color-gray-4);
  }
}

.disabledTertiary {
  background-color: transparent;
}

.lgBtn {
  line-height: 24px;
  font-weight: 700;
  font-size: var(--mantine-font-size-lg);
}

.mdBtn {
  line-height: 22px;
  font-weight: 700;
  font-size: var(--mantine-font-size-md);
}

.smBtn {
  line-height: 18px;
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
}

.xsBtn {
  line-height: 16px;
  font-weight: 600;
  font-size: var(--mantine-font-size-xs);
}

.semiboldBtnLabel {
  font-weight: 600;
}

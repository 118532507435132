@import "./_mantine.scss";
.label {
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
  padding-bottom: var(--mantine-spacing-xs);
}
.boldLabel {
  font-weight: 700;
}
.input {
  color: var(--mantine-color-blue-8);
  border: none;
  background-color: whitesmoke;
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-md);
  height: 40px;
}
.boldInput {
  font-weight: 700;
  padding: var(--mantine-spacing-lg);
}
.inputWithLeftIcon {
  padding-left: 50px !important;
}
.whiteBgInput {
  border: var(--mantine-color-blue-8) 1px solid;
  background-color: white;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-lg);
  &::placeholder {
    color: var(--mantine-color-blue-8);
  }
}
.disabledInput {
  background-color: var(--mantine-color-gray-2);
  border: none;
  color: var(--mantine-color-gray-6);
  opacity: 1;
}
.inputError {
  background: var(--mantine-color-gray-2);
  color: #d93e1c;
  border-radius: 4px;
  border: none;
}
.blueRequired {
  color: var(--mantine-color-blue-8);
}

@import "./_mantine.scss";
.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background: linear-gradient(180deg rgba(0, 0, 0, 0.3) 0% rgba(0, 0, 0, 0) 100%);
}
.borderRadiusForListings {
  border-radius: 0;
  height: 30%;
  bottom: 0;
  top: auto;
  background: linear-gradient(180deg rgba(0, 0, 0, 0) 0% rgba(0, 0, 0, 0.3) 100%);
}

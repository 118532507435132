@import "./_mantine.scss";
.labelsWrapper {
  flex-wrap: wrap;
}
.badgeLabel {
  text-align: center;
  background: var(--mantine-color-blue-2);
  color: var(--mantine-color-blue-8);
  font-weight: 400px;
  height: 30px;
  border-radius: 4px;
  text-transform: capitalize;
  &:hover {
    cursor: pointer;
  }
}
.search {
  background: var(--mantine-color-pink-6);
  color: var(--mantine-color-white);
  &:hover {
    background-color: var(--mantine-other-pinkHover) !important;
  }
}
.activityTextInput {
  background: var(--mantine-color-gray-2);
  border: none;
  height: 60px;
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-sm);

  @media (max-width: $mantine-breakpoint-sm) {
    height: 50px;
  }
}

@import "./_mantine.scss";
.dropdown {
  border: none;
  background: white;
  padding: 0;
  @media (max-width: 896px) {
    border-radius: 0;
    padding: 4px;
  }
}
.item {
  border-top: 1px solid var(--mantine-color-gray-1);
  border-radius: 0;
  font-weight: 700;
  padding: var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-sm);
}

@import "./_mantine.scss";
.checkboxInput {
  border-radius: 2px;
  border: 2px solid var(--mantine-color-blue-5);
  &:checked {
    background-color: var(--mantine-color-blue-5);
    border-color: var(--mantine-color-blue-5);
    &:disabled {
      background-color: var(--mantine-color-gray-5);
      border: none;
    }
  }
}
.checkboxIcon {
  color: var(--mantine-color-lime-5) !important;
}
.disabledIcon {
  color: var(--mantine-color-gray-2) !important;
}
.checkboxIconIndeterminate {
  color: white !important;
}
.checkboxLabel {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  padding-top: var(--mantine-spacing-md);
  padding-bottom: var(--mantine-spacing-md);
}
.checkboxLabelWrapper {
  flex-grow: 1;
}
.checkboxRoot {
  border-bottom: 1px solid var(--mantine-color-gray-1);
}
.checkboxBody {
  margin-bottom: 0;
  align-items: center;
}
.checkboxLabelSizeMd {
  font-size: var(--mantine-font-size-md);
}
.checkboxLabelNeat {
  padding-top: var(--mantine-spacing-xs);
  padding-bottom: var(--mantine-spacing-xs);
}
.disabledLabel {
  color: var(--mantine-color-gray-6) !important;
  cursor: not-allowed !important;
  font-weight: 700;
}
.checkboxInputIndeterminate {
  background: var(--mantine-color-blue-5);
  border: 0.0625rem solid #ced4da;
}
.fullWidth {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.checkboxLabelOriginal {
  font-weight: 400;
  &[data-disabled='true'] {
    color: #7f839b !important;
  }
}
// TODO - to remove later as unused
// Online banner component checkbox styles
.onlineCheckboxInput {
  background: var(--mantine-color-pink-1);
}
.fullWidthLabel {
  flex-grow: 1;
  cursor: pointer;
}

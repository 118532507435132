@import "./_mantine.scss";
.locationFilter {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--mantine-radius-lg);
  border-bottom-left-radius: var(--mantine-radius-lg);
  border: none;
  border-right: var(--mantine-color-gray-3) 1px solid;
  height: 56px;
  background: var(--mantine-color-gray-2);
  &:hover {
    background: white;
  }
}

.noRightBorder {
  border-right: none;
}

.noLeftBorder {
  border-left: none;
}

.middleFilters {
  border-radius: 0;
  border-right: var(--mantine-color-gray-3) 1px solid;
  height: 56px;
  background: var(--mantine-color-gray-2);
  &:hover {
    background: none;
  }
  &:disabled {
    opacity: 0.4;
  }
}

.wrapper {
  margin: 0 auto;
  background: var(--mantine-color-gray-2);
  border-radius: var(--mantine-radius-lg);
  border: 2px solid var(--mantine-color-gray-3);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.filterButton {
  padding-left: var(--mantine-spacing-xs);
  justify-content: start;
  text-align: start;
}

.activityFilter {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--mantine-radius-lg);
  border-bottom-right-radius: var(--mantine-radius-lg);
  height: 56px;
  background: var(--mantine-color-gray-2);
  border: none;
  border-left: var(--mantine-color-gray-3) 1px solid;
  &:hover {
    background: none;
  }
  &:disabled {
    opacity: 0.4;
  }
}

.activeLocationFilter {
  background: white;
  border-top-right-radius: var(--mantine-radius-sm);
  border-bottom-right-radius: var(--mantine-radius-sm);
  &:hover {
    background: white;
  }
}

.activeMiddleFilter {
  background: white;
  border-radius: var(--mantine-radius-sm);
  border-left: 1px solid var(--mantine-color-gray-3);
  border-right: 1px solid var(--mantine-color-gray-3);
  &:hover {
    background: white;
  }
  width: 275px;
}

.activeActivityFilter {
  background: white;
  border-right: 1px solid var(--mantine-color-gray-3);
  border-top-right-radius: var(--mantine-radius-sm);
  border-bottom-right-radius: var(--mantine-radius-sm);
  border-top-left-radius: var(--mantine-radius-sm);
  border-bottom-left-radius: var(--mantine-radius-sm);
  &:hover {
    background: white;
  }
}

.locationDropdown {
  width: 512px !important;
}

.ageDropdown {
  width: 352px !important;
}

.whenDropdown {
  width: 330px !important;
}

.activityDropdown {
  width: 391px !important;
}

.search {
  background: var(--mantine-color-pink-6);
  color: white;
  &:hover {
    background-color: var(--mantine-other-pinkHover) !important;
  }
  &:disabled {
    background-color: var(--mantine-color-gray-5);
    opacity: 0.32;
  }
}

.searchText {
  width: fit-content;
  font-weight: 700;
  padding: var(--mantine-spacing-md);
  svg {
    margin-left: 8px;
  }
}

.update {
  width: fit-content;
  padding: 0 var(--mantine-spacing-sm);
  background: var(--mantine-color-blue-8);
  @media (max-width: 1050px) {
    padding: 0;
  }
  &:hover {
    background-color: var(--mantine-color-blue-8);
  }
}

.updateText {
  margin-right: var(--mantine-spacing-xs);
  @media (max-width: 1050px) {
    display: none;
  }
}

@import "./_mantine.scss";
.btnWrapper {
  width: fit-content;
  text-decoration: none;
  border: none;
  background-color: transparent;
  color: var(--mantine-color-gray-6);
  &:hover {
    cursor: pointer;
  }
}

.primaryBtn {
  background-color: var(--mantine-color-pink-6);
  color: var(--mantine-color-white);
  border-radius: 24px;
}
.primaryBtnWrapper {
  &:hover {
    div {
      background-color: var(--mantine-other-pinkHover);
      color: var(--mantine-color-white);
    }
    p {
      text-decoration: underline;
    }
  }
}

.secondaryBtn {
  border: 1px solid var(--mantine-color-pink-2);
  background-color: var(--mantine-color-pink-1);
  color: var(--mantine-color-pink-6);
}
.secondaryBtnWrapper {
  &:hover {
    div {
      border: 2px solid var(--mantine-color-pink-2);
      svg {
        fill: var(--mantine-other-pinkHover);
      }
    }
    p {
      text-decoration: underline;
    }
  }
}

.tertiaryBtn {
  border: 1px solid var(--mantine-color-gray-4);
  background-color: transparent;
  color: var(--mantine-color-pink-6);
}
.tertiaryBtnWrapper {
  &:hover {
    div {
      border: 2px solid var(--mantine-color-pink-2);
      svg {
        fill: var(--mantine-other-pinkHover);
      }
    }
    p {
      text-decoration: underline;
    }
  }
}

.disabled {
  border: 1px solid var(--mantine-color-gray-2);
  background-color: transparent;
  svg {
    fill: var(--mantine-color-gray-4);
  }
  &:hover {
    cursor: not-allowed;
  }
}
.disabledLabel {
  color: var(--mantine-color-gray-4);
  &:hover {
    cursor: not-allowed;
  }
}

.iconRoot {
  border-radius: 60px;
}

.lgBtn {
  svg {
    width: 24px;
    height: 24px;
  }
  line-height: 18px;
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
}

.mdBtn {
  svg {
    width: 20px;
    height: 20px;
  }
  line-height: 16px;
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}

.smBtn {
  svg {
    width: 16px;
    height: 16px;
  }
  line-height: 16px;
  font-size: var(--mantine-font-size-xs);
  font-weight: 600;
}

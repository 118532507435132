@import "./_mantine.scss";
.radioLabel {
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
  &:hover {
    cursor: pointer;
  }
}
.filtersRadioLabel {
  font-weight: 600;
  font-size: var(--mantine-font-size-sm);
  padding-right: var(--mantine-spacing-xs);
}
.boldLabelFont {
  font-weight: 700;
}
.labelWrapper {
  width: 100%;
}
.desktopFilterLabelWrapper {
  width: auto;
}
.radioRoot {
  padding: 0;
  &[data-checked] {
    font-weight: 700;
  }
}
.radio {
  border: var(--mantine-color-blue-5) 2px solid;
  &:checked {
    background: var(--mantine-color-lime-5);
    border: var(--mantine-color-blue-5) 5px solid;
    height: 20px;
    width: 20px;
    z-index: 2;
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
  }
}
.radioGroupBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-block: var(--mantine-spacing-md);
}
.radioBody {
  display: flex;
  align-items: center;
}
.filtersRadioBody {
  justify-content: space-between;
  border-bottom: var(--mantine-color-gray-2) 1px solid;
  padding-bottom: var(--mantine-spacing-md);
  margin-bottom: var(--mantine-spacing-md);
}
.radioGroupLabelFilters {
  font-weight: 700;
  color: var(--mantine-color-gray-5);
  padding-bottom: var(--mantine-spacing-xs);
}
.fullWidthRadio {
  width: 100%;
}

.osano-cm-widget {
  display: none;
}

.icon {
  vertical-align: middle;
}

.CarouselWidget-prefix {
  display: none;
}

.LazyLoad {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.intercom-lightweight-app,
.intercom-lightweight-app-launcher,
.intercom-launcher,
.intercom-namespace,
.intercom-with-namespace-52k34s {
  z-index: 199 !important;
}
@import "./_mantine.scss";
.resetBtn {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: var(--mantine-color-blue-5);
  padding: 0;
  &:disabled {
    background-color: white;
    color: var(--mantine-color-blue-5);
    opacity: 0.32;
  }
  @media (max-width: $mantine-breakpoint-md) {
    height: fit-content;
  }
}

@import "./_mantine.scss";
.input {
  background: var(--mantine-color-gray-2);
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: var(--mantine-font-size-md);
}
.dropdown {
  color: #ffffff;
  background: var(--mantine-color-pink-5);
  border-radius: 4px;
  font-weight: 700;
  padding: 0;
}
.item {
  &[data-checked] {
    font-weight: bold;
    background: var(--mantine-color-gray-2);
    color: var(--mantine-color-blue-8);
  }

  &:hover {
    color: var(--mantine-color-blue-8);
    background: var(--mantine-color-gray-1);
  }
}
.label {
  display: flex;
  gap: 1px;
  color: var(--mantine-color-blue-8);
}

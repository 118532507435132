@import "./_mantine.scss";
.suggestionsDropdown {
  top: auto !important;
  left: auto !important;
}

.suggestionItem {
  color: var(--mantine-color-blue-8);
  cursor: pointer;
  padding: var(--mantine-spacing-xs);

  &:hover {
    background-color: var(--mantine-color-blue-1);
  }
}

.geolocationIcon {
  width: fit-content;
  color: var(--mantine-color-gray-5);
  margin-block: var(--mantine-spacing-sm);
  padding-left: 0;

  &[data-loading='true'] {
    background-color: var(--mantine-color-gray-1);

    svg {
      stroke: var(--mantine-color-blue-5);
    }

    &:before {
      background-color: transparent;
    }
  }
}

.input {
  color: var(--mantine-color-blue-8);
  border: none;
  background-color: whitesmoke;
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-md);
  height: 40px;
  &::placeholder {
    font-weight: 600;
  }
}

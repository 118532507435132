@import "./_mantine.scss";
.group {
  justify-content: space-between;
  align-items: center;
  height: inherit;
  width: 100%;
  margin: 0 auto;
  @media (max-width: $mantine-breakpoint-sm) {
    gap: 0;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  box-shadow: 0px 4px 24px 0px rgba(11, 23, 78, 0.1);
}

.pebbleLogo {
  height: 100%;
  margin-left: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-md) {
    height: 32px;
    // For the lifetime of the Promo banner
    margin-left: var(--mantine-spacing-sm);
  }
}

.mobileLogoWithText {
  @media (max-width: $mantine-breakpoint-md) {
    height: 37px;
    width: 135px;
    margin-left: var(--mantine-spacing-sm);
  }
}

.nav {
  width: 100%;
  top: 0;
  @media (max-width: $mantine-breakpoint-sm) {
    border: none;
    border-bottom-right-radius: var(--mantine-radius-lg);
    border-bottom-left-radius: var(--mantine-radius-lg);
  }
}

.profileLink {
  margin-right: var(--mantine-spacing-xl);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--mantine-color-blue-5);
  color: white;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  &:hover {
    background-color: var(--mantine-color-blue-6);
  }
}

//   Powered by Pebble navbar styles for specific pages
.supplierActivities {
  margin: 0 auto;
  max-width: 746px;
  padding-left: var(--mantine-spacing-md);

  @media (max-width: $mantine-breakpoint-sm) {
    max-width: 332px;
    padding: 0;
  }
}

.activityDetails {
  margin: 0 auto;
  max-width: 60rem;
  padding: 0 var(--mantine-spacing-md);

  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 2px;
  }
}

.sessionSelect {
  display: none;
}

.card {
  background-color: #fff;
  border-radius: 200px;
  padding: 0.25rem;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border: 1px solid var(--mantine-color-gray-2);
  &:hover {
    cursor: pointer;
  }
  width: 75%;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 60%;
  }
}

.filterLabel {
  font-size: var(--mantine-font-size-md);
  color: #888888;
  margin-bottom: 0;
  font-weight: 600;
  text-overflow: ellipsis;
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
  }
}

.filterHeader {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);
  line-height: 16px;
  opacity: 0.7;
  max-width: 85%;
}

.mobileSearchIcon {
  color: var(--mantine-color-blue-8);
  margin-inline: var(--mantine-spacing-xs);
}

.burgerWrapper {
  margin-inline: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-md) {
    margin-left: 0;
    margin-right: 4px;
  }
}

.inactiveSearchBarPosition {
  //width of the logo component to place search in the middle
  width: 167px;
  justify-content: flex-end;

  // TODO: check styles
  position: fixed;
  top: 0;
  right: 0;
}

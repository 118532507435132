@import "./_mantine.scss";
.dropdown {
  &:hover {
    cursor: pointer;
  }
}

.input {
  border-radius: var(--mantine-radius-xs);
  color: var(--mantine-color-blue-8);
  border: 0;
  background-color: var(--mantine-color-gray-2);
  font-size: var(--mantine-font-size-sm);
  height: 40px;
  font-weight: 600;

  @media (max-width: $mantine-breakpoint-sm) {
    height: 50px;
  }
}

.rightSection {
  pointer-events: none;
  margin-top: 4px;
  margin-right: var(--mantine-spacing-xs);
  color: var(--mantine-color-blue-8);
}

.wrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-right: 10px;
  }
}

.item {
  color: var(--mantine-color-blue-8);

  &[data-selected] {
    &,
    &:hover {
      font-weight: bold;
      background: var(--mantine-color-gray-2);
      color: var(--mantine-color-blue-8);
    }
  }
}

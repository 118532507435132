@import "./_mantine.scss";
.calendarBase {
  border-radius: 10px;

  table {
    border: none !important;
  }
}

.dayCell {
  background-color: var(--mantine-color-gray-1);
  border-radius: 0;
  color: var(--mantine-color-gray-5);

  &[data-today] {
    font-weight: 700;
  }

  &[data-weekend] {
    color: var(--mantine-color-gray-5);
  }

  &[data-selected] &[data-weekend] {
    color: white;
  }

  &[data-in-range] {
    background: #445690;
    color: #fff;
  }

  &[data-first-in-range],
  &[data-last-in-range] {
    background: var(--mantine-color-blue-8);
    border-radius: 0;
    color: #fff;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    width: calc((100vw - 32px - 32px) / 7);
    height: calc((100vw - 32px - 32px) / 7);
  }
}

.highlightMonday {
  &:nth-of-type(1) {
    color: var(--mantine-color-blue-8);
  }
}

.highlightTuesday {
  &:nth-of-type(2) {
    color: var(--mantine-color-blue-8);
  }
}

.highlightWednesday {
  &:nth-of-type(3) {
    color: var(--mantine-color-blue-8);
  }
}

.highlightThursday {
  &:nth-of-type(4) {
    color: var(--mantine-color-blue-8);
  }
}

.highlightFriday {
  &:nth-of-type(5) {
    color: var(--mantine-color-blue-8);
  }
}

.highlightSaturday {
  &:nth-of-type(6) {
    color: var(--mantine-color-blue-8);
  }
}

.highlightSunday {
  &:nth-of-type(7) {
    color: var(--mantine-color-blue-8);
  }
}

.calendarHeaderLevel {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
}

.calendarHeader {
  background-color: var(--mantine-color-gray-1);
  margin-bottom: 0;
  padding-bottom: 0.5rem;

  @media (max-width: $mantine-breakpoint-sm) {
    max-width: calc(100vw - 32px - 32px);
  }
}

.calendarControl {
  svg {
    stroke: var(--mantine-color-blue-8);
    stroke-width: 0.8;
  }
}

.subheading {
  color: var(--mantine-color-gray-5);
  font-weight: 600;
  font-size: var(--mantine-font-size-xs);
  padding-bottom: var(--mantine-spacing-xs);
  padding-top: var(--mantine-spacing-md);
}

.dayOfWeekButton {
  border-radius: 100%;
  width: 34px;
  height: 34px;
  padding: 0;
  border: 1px solid var(--mantine-color-blue-8);
  background-color: var(--mantine-white);
  color: var(--mantine-color-blue-8);

  &:hover {
    background-color: var(--mantine-color-blue-1);
    color: var(--mantine-color-blue-8);
  }

  @media (max-width: $mantine-breakpoint-sm) {
    width: 36px;
    height: 36px;
  }
}

.dayOfWeekButtonSelected {
  opacity: 1;
  background-color: var(--mantine-color-blue-8);
  color: white;

  &:hover {
    background-color: var(--mantine-other-blueHover);
    color: white;
  }
}

.allButtonSelected {
  background-color: rgba(14, 32, 111, 0.2);
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  border: 1px solid var(--mantine-color-blue-8);
}

.weekdaysRow {
  border-bottom: 0.84px solid rgba(127, 131, 155, 0.32);
}

.weekdays {
  font-size: 11px;
}

.handlingAllWeekdaysBtn {
  color: var(--mantine-color-blue-8);
  font-weight: 600;
  background-color: white;
  padding-inline: 1.15rem;
  --_chip-bd: 1px solid var(--mantine-color-blue-8) !important;

  &[data-checked] {
    background-color: var(--mantine-color-blue-8) !important;
    color: white;
    border: none;
    padding-inline: 1.15rem;
  }

  @media (min-width: $mantine-breakpoint-md) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.chipIcon {
  display: none;
}
